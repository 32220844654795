$yellow-primary: #ffca00;
$yellow-lighter: #ffe066;
$yellow-light: #ffd633;
$yellow-dark: #cca300;
$yellow-darker: #997a00;

$softblue-primary: #949eb6;
$softblue-light: #bcc9e8;
$softblue-dark: #697182;

$dark-primary: #383838;
$dark-light: #6b6b6b;
$dark-dark: #050505;

$blue-primary: #5885f7;
$blue-lighter: #c2d3ff;
$blue-light: #8fafff;
$blue-dark: #476ac4;
$blue-darker: #344f91;

$green-primary: #33b87e;
$green-lighter: #adffdc;
$green-light: #42eba1;
$green-dark: #25855b;
$green-darker: #175238;

$orange-primary: #fe7b03;
$orange-lighter: #ffb169;
$orange-light: #ff9736;
$orange-dark: #cc6402;
$orange-darker: #994b02;

$red-primary: #db4251;
$red-lighter: #ff808c;
$red-light: #ff4c5e;
$red-dark: #a8323e;
$red-darker: #75232b;

$grey-primary: #666666;
$grey-lighter: #cccccc;
$grey-light: #999999;
$grey-dark: #333333;
$grey-darker: #000000;

$ice-primary: #f8f8fd;
$ice-lighter: #f0edf7;
$ice-dark: #c5c5c9;

$grey900: #8a94a6;
$grey800: #98a1b1;
$grey700: #a6aebc;
$grey600: #b5bbc6;
$grey500: #c9ced6;
$grey400: #e1e4e8;
$grey300: #f1f2f4;
$grey200: #f8f9fb;
$grey100: #fcfcfd;

$yellow900: #b38f00;
$yellow800: #cca300;
$yellow700: #e6b800;
$yellow600: #ffca00;
$yellow500: #ffd426;
$yellow400: #ffdb4c;
$yellow300: #ffe373;
$yellow200: #ffeb99;
$yellow100: #fff2bf;

$black900: #0a1f44;
$black800: #182c4f;
$black700: #273a5b;
$black600: #364866;
$black500: #445571;
$black400: #53627c;
$black300: #5d6c84;
$black200: #6c798f;
$black100: #758196;

$red900: #8f1522;
$red800: #a8222f;
$red700: #c2303f;
$red600: #db4251;
$red500: #e85d6b;
$red400: #f57a87;
$red300: #ff99a3;
$red200: #ffb3ba;
$red100: #ffccd1;

$green900: #188555;
$green800: #249e69;
$green700: #33b87e;
$green600: #4bc490;
$green500: #64d1a2;
$green400: #81deb5;
$green300: #a0ebca;
$green200: #c1f7e0;
$green100: #e0fff2;

$blue900: #335cc4;
$blue800: #4570de;
$blue700: #5885f7;
$blue600: #759cff;
$blue500: #8fafff;
$blue400: #a8c1ff;
$blue300: #c2d3ff;
$blue200: #dbe5ff;
$blue100: #f5f8ff;

$orange700: #ffa319;
$orange100: #fff7eb;

$white: #ffffff;
$backgroundColor: #f5f6fa;

$gradient1: linear-gradient(107deg, #57d968, #00d590);
$gradient2: linear-gradient(to bottom, #1285ff, #20d1ff);
$gradient3: linear-gradient(107deg, #916bff, #3366ff);
$gradient4: linear-gradient(107deg, #ff5980, #ffa882);
$gradient5: linear-gradient(107deg, #d438ff, #8c58ff);
