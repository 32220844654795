@import './easymde.css';
@import 'variables.scss';
@import './bootstrap.min.css';
@import 'quill/dist/quill.snow.css';
@import 'animate.css/animate.min.css';
@import 'nouislider/dist/nouislider.min.css';
@import 'css-star-rating/css/star-rating.css';
@import 'intl-tel-input/build/css/intlTelInput.css';

.animation-popover {
  border: none;
  box-shadow: 0px 0px 15px rgb(10 31 68 / 10%);
  border-radius: 6px;

  .popover-arrow::before {
    border: none;
  }
}

.amp-card-help-button {
  position: absolute;
  bottom: 4px;
  right: 10px;
  color: #999999;
}

.amp-markdown {
  margin: 0;
  padding: 0;
  p {
    font-size: 12px;
    color: #445571;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 18px;
  }
  h1 {
    font-size: 21px;
    color: #0a1f44;
    margin-top: 0;
    margin-bottom: 1;
  }
  a {
    text-decoration: none;
    font-weight: bold;
    color: #007bff;
  }
}

.amp-text-red {
  color: $red600;
}

.dark-mode .amp-markdown {
  p,
  em,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a,
  strong {
    color: #ffffff;
  }
}

.amp-markdown-summary {
  h3 {
    font-size: 14px;
    font-weight: 700;
  }
  p {
    margin-bottom: 0;
  }
  ul {
    margin-bottom: 8px;
  }
}

.amp-markdown-email {
  margin: 0;
  padding: 0;
  center {
    font-size: 14px;
    color: #8a94a6;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 12px;
  }
  p,
  pre,
  code {
    font-size: 14px;
    color: #8a94a6;
    font-weight: 500;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 12px;
    font-family: Montserrat, sans-serif;
    span {
      font-size: 14px;
      color: #8a94a6;
      font-weight: 500;
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 12px;
      font-family: Montserrat, sans-serif;
    }
  }
  ul,
  ol {
    color: #445571;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 12px;
  }
  h1 {
    font-size: 36px;
    color: #445571;
    line-height: 1.2;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 12px;
  }
  h2 {
    font-size: 32px;
    color: #445571;
    line-height: 1.2;
    margin-bottom: 12px;
  }
  h3 {
    font-size: 28px;
    color: #445571;
    line-height: 1.2;
    margin-bottom: 12px;
  }
  h4 {
    font-size: 24px;
    color: #445571;
    line-height: 1.2;
    margin-bottom: 12px;
  }
  h5 {
    font-size: 20px;
    color: #445571;
    line-height: 1.2;
    margin-bottom: 12px;
  }
  h6 {
    font-size: 16px;
    color: #445571;
    line-height: 1.2;
    margin-bottom: 12px;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: #007bff;
  }
  img {
    width: 100%;
    height: auto;
  }
}

/*******************
      Mixins
*******************/
@mixin ampPlaceholder($color) {
  &::-webkit-input-placeholder {
    color: $color !important;
  }
  &:-moz-placeholder {
    color: $color !important;
  }

  &::-moz-placeholder {
    color: $color !important;
  }
  &:-ms-input-placeholder {
    color: $color !important;
  }
}

/*******************
  Utility Classes
*******************/

html,
body {
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  margin: 0;
  height: 100%;
  background-color: $backgroundColor;
  *:focus {
    outline: 0 !important;
  }
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

:host {
  display: flex;
  flex: 1;
}

body {
  margin: 0;
}

a {
  text-decoration: none !important;
  cursor: pointer !important;
}

.row {
  margin-bottom: 0;
  .col {
    padding: 0.75rem;
  }
}

textarea.amp-input {
  padding: 10px;
}

small {
  font-size: 40%;
  vertical-align: super;
}

.noty_theme__amp .noty-icon {
  font-size: 20px;
  margin-right: 12px;
}

.noty_theme__amp .bi-x-lg {
  position: absolute;
  top: 20px;
  right: 18px;
  font-size: 8px;
}
.noty_theme__amp p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  margin: 0;
  font-family: Montserrat, sans-serif;
}
.noty_theme__amp .noty_text {
  margin-left: 16px;
}

.noty_theme__amp .noty_body {
  display: inline-flex;
}

.noty_theme__amp {
  min-width: 368px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 18px 18px 0 rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1);
  padding-bottom: 14px;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
  display: inline-flex;
  margin-bottom: 16px;
}

.noty_theme__amp.noty_type__error {
  background-color: $red200;
  p {
    color: $red600;
  }
  .noty-icon {
    color: $red600;
  }
  .bi-x-lg {
    color: $red800;
  }
}

.noty_theme__amp.noty_type__info {
  background-color: $blue300;
  p {
    color: $blue900;
  }
  .noty-icon {
    color: $blue700;
  }
  .bi-x-lg {
    color: $blue800;
  }
}

.noty_theme__amp.noty_type__warning {
  background-color: $yellow200;
  p {
    color: $yellow700;
  }
  .noty-icon {
    color: $yellow800;
  }
  .bi-x-lg {
    color: $yellow900;
  }
}

.noty_theme__amp.noty_type__success {
  background-color: $green200;
  p {
    color: $green900;
  }
  .noty-icon {
    color: $green700;
  }
  .bi-x-lg {
    color: $green800;
  }
}

.noty_theme__amp.noty_type__notification {
  background-color: $white;
  p {
    color: $black900;
  }
  .noty-icon {
    color: $blue700;
  }
  .bi-x-lg {
    color: $grey900;
  }
}

#noty_layout__topRight {
  width: auto !important;
  max-width: 400px !important;
  right: 24px !important;
  top: 16px !important;
}

/*******************
  Star Rating Modifier
*******************/

.rating {
  justify-content: left !important;
}

star-rating-comp {
  .rating {
    margin-bottom: 0 !important;
  }
}

.star-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.amp__dash__card--rating .star-container {
  position: absolute;
  right: 0;
  left: 0;
}

.amp-main-title {
  margin-bottom: 0;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black500;
  display: inline-block;
}

button {
  -webkit-appearance: button;
  appearance: button;
}

[class*='amp-btn'],
.pagination .page-item .page-link {
  height: 36px;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  text-align: center;
  line-height: 36px;
  padding: 0 16px;
  color: $white;
  border-radius: 4px;
  background-color: $blue-primary;
  border: none;
  border-color: $blue-primary;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  -webkit-transition: all 0.2s ease;
  -webkit-transation-delay: 0;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;

  &:hover {
    background-color: $blue-dark;
    border-color: $blue-dark;
    color: $white;
    outline: none !important;
  }

  &:disabled {
    background-color: $ice-primary;
    border: dashed 1px $softblue-light;
    color: $softblue-light;
    &:hover {
      background-color: $ice-primary;
      border: dashed 1px $softblue-light;
      color: $softblue-light;
      cursor: not-allowed;
    }
  }
}

.ampl-btn-group {
  display: flex;
  .amp-btn {
    flex: 1;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    background-color: transparent;
    border: 1px solid $blue700;
    border-left: none;
    border-right: none;
    color: $blue700;
    height: 32px;
    &:hover {
      background-color: $blue300;
    }
    &.active {
      background-color: $blue700;
      color: $white;
    }
  }
  :first-child {
    border: 1px solid $blue700;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  :last-child {
    border: 1px solid $blue700;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

[class*='amp-btn-outline'] {
  height: 34px !important;
  line-height: 36px !important;
  box-sizing: content-box;
  border: solid 1px;
}

.amp-btn-lg {
  height: 48px;
  line-height: 48px;
}

.amp-btn-sm {
  height: auto;
  line-height: 22px;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
}

.amp-btn-gradient1 {
  background-image: $gradient1;
}

.amp-btn-gradient5 {
  background-image: $gradient5;
}

.amp-btn-blue200 {
  background-color: $blue200;
  color: $blue700;
}

.amp-interpolation-button {
  background: #f1f2f4;
  color: #8a94a6;
  font-size: 12px;
  padding: 0px 6px;
  cursor: pointer;
  border-radius: 2px;
  margin-bottom: 4px;
  user-select: none;
}

.amp-new-dropdown {
  border: none;
  padding: 8px;
  border-radius: 6px;
  box-shadow: 0 8px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
  .amp-new-dropdown-option {
    color: #364866;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    padding: 8px 12px;
    &:hover {
      color: #5885f7;
      background-color: #f5f8ff;
    }
  }
}

// Normal Buttons
.amp-btn-primary {
  background: $blue700;
  border-color: $blue700;
  &:hover {
    background-color: $blue900;
    border-color: $blue900;
  }
}

.amp-btn-secondary {
  background: $black100;
  border-color: $black100;
  &:hover {
    background-color: $black400;
    border-color: $black400;
  }
}

.amp-btn-success {
  background: $green700;
  border-color: $green700;
  &:hover {
    background-color: $green900;
    border-color: $green900;
  }
}

.amp-btn-danger {
  background: $red600;
  border-color: $red600;
  &:hover {
    background-color: $red900;
    border-color: $red900;
  }
}

.amp-btn-warning {
  background: $yellow700;
  border-color: $yellow700;
  color: $black900;
  &:hover {
    background-color: $yellow900;
    border-color: $yellow900;
    color: $black900;
  }
}

.amp-btn-light {
  background: $ice-primary;
  border-color: $ice-primary;
  color: $grey-primary;
  &:hover {
    background-color: $grey-lighter;
    border-color: $grey-lighter;
    color: $grey-primary;
  }
}

.amp-btn-dark {
  background: $dark-primary;
  border-color: $dark-primary;
  &:hover {
    background-color: $dark-dark;
    border-color: $dark-dark;
  }
}

.amp-btn-link,
.pagination .page-item .page-link {
  background: transparent;
  border-color: transparent;
  color: $blue700;
  &:hover {
    background-color: transparent;
    border-color: transparent;
    color: $blue900;
  }
}

.amp-btn-link-danger {
  background: transparent;
  border-color: transparent;
  color: $red600;
  &:hover {
    background-color: transparent;
    border-color: transparent;
    color: $red900;
  }
}

.amp-btn-outline-cancel {
  background: transparent;
  border: solid 1px $grey400;
  color: $black400;

  &:hover {
    background-color: $black100;
    border: 1px solid;
    border-color: $black100;
  }
}

.amp-btn-outline-primary {
  background: transparent;
  color: $blue700;
  border-color: $blue700;
  &:hover {
    background-color: $blue100;
    border-color: $blue700;
    color: $blue700;
  }
}

.amp-btn-outline-secondary {
  background: transparent;
  color: $black900;
  border-color: $grey400;
  &:hover {
    background-color: $grey200;
    border-color: $grey400;
    color: $black900;
  }
}

.amp-btn-outline-success {
  background: transparent;
  color: $green-primary;
  border-color: $green-primary;
  &:hover {
    background-color: $green-primary;
    border-color: $green-primary;
  }
}

.amp-btn-outline-danger {
  background: transparent;
  color: $red-primary;
  border-color: $red-primary;
  &:hover {
    background-color: $red-primary;
    border-color: $red-primary;
  }
}

.amp-btn-outline-warning {
  background: transparent;
  color: $yellow-primary;
  border-color: $yellow-primary;
  &:hover {
    background-color: $yellow-primary;
    border-color: $yellow-primary;
    color: $dark-primary;
  }
}

.amp-btn-outline-light {
  background: transparent;
  color: $ice-primary;
  border-color: $ice-primary;
  &:hover {
    background-color: $ice-primary;
    border-color: $ice-primary;
    color: $grey-primary;
  }
}

.amp-btn-outline-dark {
  background: transparent;
  color: $dark-primary;
  border-color: $dark-primary;
  &:hover {
    background-color: $dark-primary;
    border-color: $dark-primary;
  }
}

.amp-filters {
  margin-top: 48px;
  .amp-filter-title {
    display: inline-block;
    font-weight: bold;
    font-size: 18px;
    color: $blue-darker;
    margin-right: 8px;
  }

  .amp-field {
    .amp-input {
      width: 100%;
    }
    margin-bottom: 0px;
  }

  star-rating {
    margin-left: 4px;
    margin-right: 24px;
  }
}

.amp-not-found {
  font-weight: 700;
  font-size: 14px;
  color: #c9ced6;
  flex-grow: 1;
  text-align: center;
  align-content: center;
  align-self: center;
}

.amp-network-selector {
  border-radius: 100%;
  width: 48px;
  height: 48px;
  background-color: $grey300;
  border: none;
  margin-right: 24px;
  margin-top: 8px;
  margin-bottom: 24px;
  position: relative;
  cursor: pointer;
  padding: 0;
  img {
    height: auto;
    width: 26px;
  }
  .amp-badge {
    display: block;
    position: absolute;
    top: -2px;
    right: -2px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    &.bi-check-lg {
      background-color: $blue700;
      font-size: 10px;
    }
    &.bi-clock {
      background-color: $yellow700;
      font-size: 10px;
    }
    &.bi-x-lg {
      background-color: $red500;
      font-size: 8px;
    }
  }

  &.active {
    background-color: $blue200;
  }
}

::-webkit-search-cancel-button {
  display: none;
}

.tooltip {
  z-index: 99999;
}

.tooltip-menu {
  z-index: 999999 !important;
  position: fixed;
}

.tooltip-optin {
  max-width: none;
}

.tooltip-inner {
  padding: 4px;
  font-family: Montserrat, sans-serif;
  background-color: #616161;
  border-radius: 6px;
  font-size: 12px !important;
  font-weight: 500;
}

.bs-tooltip-left .arrow::before {
  border-left-color: #616161 !important;
}
.bs-tooltip-right .arrow::before {
  border-right-color: #616161 !important;
}
.bs-tooltip-top .arrow::before {
  border-top-color: #616161 !important;
}
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #616161 !important;
}

star-rating {
  display: inline-block;
}

.amp-dropdown {
  #option-dropdown {
    z-index: 997;
  }
}

.amp-card {
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.amp-collapsed-field {
  display: inline-block;
  margin-bottom: 24px;
  border-radius: 4px;
  background-color: $blue200;
  font-size: 12px;
  font-weight: 500;
  color: #007fff;
  margin-right: 12px;
  padding: 4px 8px;
  cursor: pointer;
  transition: opacity ease-in-out 0.3s;
  &:hover {
    opacity: 0.6;
  }
  span {
    vertical-align: baseline;
    margin-right: 4px;
  }
}

.block {
  width: 100%;
}
.amp-field {
  display: block;
  margin-bottom: 32px;
}

.amp-label-text {
  text-align: left;
  display: block;
  margin-bottom: 5px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: $grey900;
}

[class*='amp-input'] {
  width: 100%;
  background: $white;
  display: block;
  height: 36px;
  border-radius: 4px;
  border: solid 1px $grey400;
  font-size: 14px;
  font-weight: 500;
  color: $black900;
  padding-left: 16px;
  padding-right: 16px;
  caret-color: $black900;
  @include ampPlaceholder($grey700);
  &:focus {
    border-color: $blue-primary !important;
    outline: none !important;
  }
  &.ng-invalid.ng-dirty.ng-touched {
    border-color: $red-primary;
    color: $red-primary;
    @include ampPlaceholder($red-primary);
  }
  &:disabled {
    border: dashed 1px $softblue-light;
    color: $softblue-light;
    opacity: 0.8;
  }
}

.amp-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 22px !important;
  position: relative;
  cursor: pointer;
}
.amp-select-new {
  position: relative;
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
  }
  span {
    pointer-events: none;
    position: absolute;
    right: 18px;
    top: 0;
    bottom: 0;
    line-height: 90px;
    color: $grey500;
  }
}
.amp-select:after {
  font-family: 'AmpIcon' !important;
  content: '\e92f' !important;
  color: $black800;
  font-size: 20px;
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 32px;
  top: calc(50% - 10px);
  width: 0;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.amp-input-lg {
  height: 48px;
}

textarea.amp-input {
  resize: none;
  height: auto;
}

select:required:invalid {
  color: #a6aebc;
}

.theme-amp {
  border-radius: 6px;
  box-shadow: 0 26px 26px 0 rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1);
}

.theme-amp-flat {
  border-radius: 6px;
  box-shadow: none !important;
}

.theme-amp .bs-datepicker-head {
  background-color: #fff;
}

.theme-amp-flat .bs-datepicker-body .days tbody tr td span {
  font-size: 13px !important;
  font-weight: 400;
  line-height: 32px;
}

.theme-amp-flat .bs-datepicker-body .weeks thead tr th {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 12px;
}

.theme-amp-flat .bs-datepicker-body table td span.selected,
.theme-amp-flat .bs-datepicker-body table td.selected span,
.theme-amp-flat .bs-datepicker-body table td span[class*='select-']:after,
.theme-amp-flat .bs-datepicker-body table td[class*='select-'] span:after {
  background-color: $blue700;
  color: #fff !important;
  border-radius: 4px;
}

.theme-amp-flat .bs-datepicker-body table td span:hover {
  background-color: $blue100;
  color: $blue700;
  border-radius: 4px;
}

.theme-amp-flat .bs-datepicker-body table td span:before {
  border-radius: 4px !important;
}

.theme-amp-flat .bs-datepicker-body table.days span.in-range:not(.select-end):before {
  background-color: $blue100 !important;
}

.theme-amp .bs-datepicker-body {
  border: none;
}

.theme-amp .bs-datepicker-body table td span.selected,
.theme-amp .bs-datepicker-body table td.selected span,
.theme-amp .bs-datepicker-body table td span[class*='select-']:after,
.theme-amp .bs-datepicker-body table td[class*='select-'] span:after {
  background-color: $blue700;
  color: #fff !important;
}

.theme-amp .bs-datepicker-body table td span.in-range {
  color: $blue700;
}
.theme-amp .bs-datepicker-body table.days td.active:not(.select-start):before,
.theme-amp .bs-datepicker-body table.days td.in-range:not(.select-end):before,
.theme-amp .bs-datepicker-body table.days span.active:not(.select-start):before,
.theme-amp .bs-datepicker-body table.days span.in-range:not(.select-end):before {
  background-color: $blue400;
}

.theme-amp .bs-datepicker-body table.days span.is-highlighted.in-range:before,
.bs-datepicker-body table.days span.in-range.select-end:before {
  background: none;
}

.theme-amp .bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.theme-amp .bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: $blue400;
  color: $blue700;
}

.theme-amp .bs-datepicker-body table td span {
  color: $black300;
}

.theme-amp .bs-datepicker-head button {
  color: $blue700;
}

.theme-amp .bs-datepicker-body table td.week span {
  color: $blue700;
}

.theme-amp .bs-datepicker-body {
  .week {
    display: none;
  }
  th:not([aria-label='weekday']) {
    display: none;
  }
}

.theme-amp .bs-datepicker-body table td span:before {
  left: -16px;
  right: -16px;
  border-radius: 100px;
}

.theme-amp .bs-datepicker-body table td span.is-other-month,
.theme-amp .bs-datepicker-body table td span.disabled {
  opacity: 0.3;
}

.pagination .page-item {
  display: inline-block;
  .page-link {
    color: $blue-light;
    cursor: pointer;
    &:focus {
      box-shadow: none;
    }
  }

  &.active {
    .page-link {
      color: $blue-primary;
      font-weight: bold;
    }
  }

  &.disabled {
    .page-link {
      color: $blue-lighter;
    }
  }
}

.pagination {
  display: block;
  text-align: center;
  margin-bottom: 0;
}

.amp-pagination {
  padding-top: 16px;
  padding-bottom: 16px;
}

nouislider {
  width: 200px;
  .noUi-horizontal {
    height: 4px;
    border: none;
    background-color: $softblue-light;
    border-radius: 10px;
    box-shadow: none;
    .noUi-connect {
      background-color: $blue900;
    }
    .noUi-handle {
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
      height: 24px;
      width: 24px;
      border: none;
      background-color: $blue-primary;
      border-radius: 100%;
      top: -10px;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);
      transition: 0.2s all;
    }
    .noUi-active {
      background-color: $blue900;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
      transition: 0.2s all;
      .noUi-tooltip {
        opacity: 1;
        transition: 0.1s opacity;
      }
    }

    .noUi-tooltip {
      transition: 0.1s opacity;
      opacity: 0;
      top: 18px;
      background: transparent;
      border: none;
      font-weight: 700;
      color: $blue700;
    }
  }
}

.amp-list-empty {
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
  color: $softblue-primary;
  img {
    width: 400px;
    height: auto;
    margin-bottom: 16px;
  }
}

.amp-modal-player {
  max-width: 50%;
  .modal-content {
    padding-top: 40px;
  }
}

.amp-modal-large {
  width: 1000px;
  .modal-content {
    margin-top: 7% !important;
  }
}

.amp-modal-lg {
  max-width: auto;
  .modal-content {
    margin-top: 96px;
  }
}

.amp-modal {
  max-width: 500px;
}

.amp-modal-ai {
  max-width: 600px;
  display: flex;
  justify-content: center;
  margin-top: 25vh;
  .modal-content {
    margin: 0;
    height: fit-content;
    padding: 8px;
  }
}

.amp-modal-filter {
  max-width: 1222px;
  margin-top: 64px;
  .modal-content {
    height: auto;
  }
}

.amp-modal-sheets {
  max-width: 1222px;
}

.amp-modal-medium {
  max-width: 600px;
}

.amp-modal-md {
  max-width: 610px;
}

.amp-error-dialog {
  max-width: 600px;
}

.amp-survey-dialog {
  max-width: 866px;
  .modal-content {
    padding: 0px;
  }
}

.amp-tag-manager-dialog {
  max-width: 700px;
  max-height: 600px;
  height: 600px;
}

.amp-modal-800 {
  max-width: 800px;
}

.modal-backdrop.show {
  opacity: 0.1 !important;
}

.modal-content {
  box-shadow: 0 26px 26px 0 rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1);
  padding: 24px 32px;
  margin-top: 64px;
  border: none;
  border-radius: 6px;
  .amp-dialog-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 4px;
    color: $blue-darker;
  }
  .amp-dialog-subtitle {
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 16px;
    white-space: pre-line;
    color: $softblue-primary;
  }
  .amp-dialog-close {
    position: absolute;
    top: 18px;
    right: 18px;
    font-size: 8px;
    color: $black800;
    cursor: pointer;
  }

  .amp-btn-light {
    margin-right: 16px;
  }
}

.amp-modal-responsive {
  .modal-content {
    margin: 4% auto;
    padding: 20px;
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .modal {
    left: 0;
  }
}

.amp-switch {
  display: none;
  margin-top: 32px;
}
.amp-div-switch {
  width: 32px;
  height: 18px;
  display: inline-block;
}
.amp-switch-label {
  position: absolute;
  display: inline-block;
  min-width: 112px;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-left: 0;
  margin-top: 4px;
}
.amp-switch-label:before,
.amp-switch-label:after {
  content: '';
  position: absolute;
  margin: 0;
  outline: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.amp-switch-label:before {
  width: 32px;
  height: 18px;
  background-color: $grey500;
  border-radius: 12px;
}
.amp-switch-label:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  background-color: $white;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(10, 31, 68, 0.16);
}
.amp-switch:checked + .amp-switch-label:before {
  background-color: $green600;
}
.amp-switch:checked + .amp-switch-label:after {
  -ms-transform: translateX(14px);
  -webkit-transform: translateX(14px);
  transform: translateX(14px);
}
.amp-switch:disabled + .amp-switch-label:before {
  background-color: #e1e1e1;
}
.amp-switch:disabled + .amp-switch-label:after {
  background-color: #f1f1f1;
}

.amp-switch-status-text {
  margin-left: 38px;
  margin-bottom: 0;
  margin-top: 4px;
  line-height: 18px;
  vertical-align: top;
  font-size: 14px;
}

.amp-guide-text {
  margin-bottom: 24px;
  line-height: 1.5;
  font-weight: 500;
  color: $black400;
}

.amp-close-dialog-button {
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 16px;
  font-size: 10px;
}

.amp-radio-button {
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 26px;
  line-height: 18px;
  margin: 0;
  margin-right: 24px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 8px;
  color: $grey600;
  .text {
    color: $black400;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 2px solid $grey500;
    border-radius: 50%;
    &::after {
      content: '';
      position: absolute;
      display: none;
      top: 3px;
      left: 3px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $blue700;
    }
  }

  input:checked ~ .checkmark {
    border-color: $blue700;
    &::after {
      display: block;
    }
  }
}

.ngxp__container[x-placement^='top'] > .ngxp__arrow {
  border-top-color: $white !important;
}
.ngxp__container[x-placement^='bottom'] > .ngxp__arrow {
  border-bottom-color: $white !important;
}

.amp-phone-input {
  .input-group {
    border-radius: 4px;
    border: 1px solid #e1e4e8;
    &:focus {
      border-color: $blue-primary;
    }
  }

  .form-control {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.amp-card-about {
  display: inline-block;
  font-size: 12px;
  padding: 4px;
  border: 1px solid #98a1b1;
  border-radius: 100%;
  float: right;
  color: #98a1b1;
  width: 22px;
  height: 22px;
}

.amp-navigation {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  background: $white;
  padding: 14px 32px;
  box-shadow: 0 1px 1px 0 rgba(10, 31, 68, 0.08), 0 0 1px 0 rgba(10, 31, 68, 0.08);
  .amp-navigation-buttons-left {
    float: left;
    .amp-btn-outline-primary {
      margin-right: 24px;
    }
  }

  .amp-navigation-buttons-right {
    float: right;
  }
}

.star-container {
  div {
    cursor: pointer !important;
  }
}

.loader {
  position: relative;
  margin: 32px auto;
  width: 48px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: $red500;
    }
    40% {
      stroke: $blue700;
    }
    66% {
      stroke: $green700;
    }
    80%,
    90% {
      stroke: $yellow500;
    }
  }
}

.modal-xs {
  max-width: 984px;
}

.amp-message-error {
  color: $red600;
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: 1.25;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.amp-popover-thin {
  font-family: Montserrat, sans-serif;
  box-shadow: 0px 0px 26px rgba(10, 31, 68, 0.12);
  border: none;
  border-radius: 4px;
  min-width: 330px;
  max-width: 500px;
  z-index: 1040;
  .tooltip .arrow {
    position: absolute !important;
    display: block !important;
    width: 0.8rem !important;
    height: 0.4rem !important;
    bottom: 0 !important;
    &:before {
      border-top-color: #616161 !important;
      top: 0px !important;
      border-width: 0.4rem 0.4rem 0 !important;
    }
    &:after {
      display: none;
    }
  }
  .popover-body {
    padding: 12px;
  }
  .popover-arrow.arrow {
    width: 16px;
    height: 16px;
    background: #fff;
    transform: rotate(45deg);
    border-radius: 2px;
    top: 24px;
    left: -7px;
    z-index: -1;
    &:before,
    &:after {
      content: none;
    }
  }
}

.amp-circle-image-display-popover {
  .popover-body {
    display: flex !important;
    flex-direction: column;
  }
}

.amp-fit-popover {
  max-width: max-content !important;
}

.amp-filter-popover {
  display: flex;
  .popover-content {
    display: flex;
    .amp-filter {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
    .amp-values {
      display: flex;
      flex-direction: column;
      max-height: 350px;
      overflow: auto;
      padding: 8px;
      .amp-badge {
        width: max-content;
        padding: 4px 8px;
        background-color: #e1e4e8;
        color: #8a94a6;
        font-size: 12px;
        border-radius: 4px;
        margin-bottom: 4px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.amp-popover {
  z-index: 9999;
  font-family: Montserrat, sans-serif;
  box-shadow: 0px 0px 26px rgba(10, 31, 68, 0.12);
  border: none;
  border-radius: 8px;
  min-width: 330px;
  max-width: 500px;
  .tooltip .arrow {
    position: absolute !important;
    display: block !important;
    width: 0.8rem !important;
    height: 0.4rem !important;
    bottom: 0 !important;
    &:before {
      border-top-color: #616161 !important;
      top: 0px !important;
      border-width: 0.4rem 0.4rem 0 !important;
    }
    &:after {
      display: none;
    }
  }

  .popover-body {
    padding: 16px;
  }

  .popover-arrow.arrow {
    width: 16px;
    height: 16px;
    background: #fff;
    transform: rotate(45deg);
    border-radius: 2px;
    z-index: -1;
    &:before,
    &:after {
      content: none;
    }
  }
}

.requests-popover {
  width: 405px;
}

.popover-arrow {
  margin-left: auto !important;
}

.loading-ellipsis {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis 2s infinite;
  -moz-animation: ellipsis 2s infinite;
  animation: ellipsis 2s infinite;
}

@keyframes ellipsis {
  from {
    width: 2px;
  }
  to {
    width: 12px;
  }
}

@-webkit-keyframes ellipsis {
  from {
    width: 2px;
  }
  to {
    width: 12px;
  }
}

@-moz-keyframes ellipsis {
  from {
    width: 2px;
  }
  to {
    width: 12px;
  }
}

amp-loading {
  flex-grow: 1;
  align-content: center;
}

.spinner {
  color: #8792a2;
  width: 16px;
  height: 16px;
  transform-origin: 50% 50%;
  transition-timing-function: ease;
  transition-property: opacity, transform;
  animation: spin 0.25s ease normal, spin 0.6s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.amp-highlighted-word {
  background: $green200;
  padding: 3px;
  border-radius: 4px;
}

.amp-interpolation {
  background: $grey300;
  padding: 3px;
  border-radius: 4px;
}

iframe {
  width: 100%;
  height: 600px;
  display: block;
  margin: 0 auto;
  text-align: center;
}

pre {
  font-weight: 400;

  .number-line {
    color: #a3acb9;
    user-select: none;
    padding-right: 10px;
  }
  .string {
    color: #09825d;
  }
  .number {
    color: #ed5f74;
  }
  .boolean {
    color: #067ab8;
  }
  .null {
    color: #bb5504;
  }
  .key {
    color: #1a1f36;
  }
}

.cdk-drag-preview {
  .amp-card {
    border: 3px solid #5885f7 !important;
  }
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
::-webkit-scrollbar-thumb {
  background: #c0d0e6;
  border: 8px none #fff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9aaabe;
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-thumb:active {
  background: #9aaabe;
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #d9e3f0;
  border: 9px none #fff;
  border-radius: 10px;
  margin: 0;
}
::-webkit-scrollbar-track:hover {
  background: #d9e3f0;
}
::-webkit-scrollbar-track:active {
  background: #d9e3f0;
}
::-webkit-scrollbar-corner {
  background: 0 0;
}

.amp-question-metric-chart-wrapper {
  .chart-path {
    transform-origin: center;
    transition-property: transform;
    transition-timing-function: ease-in;
    transition-duration: 1s;
  }

  .chart-metric-nps {
    .chart-path-great {
      transition-delay: 0.45s;
      transition-duration: 1.2s;
    }
    .chart-path-high {
      transition-delay: 0.3s;
      transition-duration: 1.2s;
    }
    .chart-path-medium {
      transition-delay: 0.25s;
      transition-duration: 1s;
    }
    .chart-path-low {
      transition-delay: 0s;
      transition-duration: 1s;
    }
    .chart-path-partialbg1 {
      transform-origin: center;
      transform: rotate(0deg);
      transition-delay: 0s;
      z-index: 5;
    }
  }
  .chart-metric-scale {
    .chart-path-partialbg1,
    .chart-path-partialbg2,
    .chart-path-partialbg3 {
      transform-origin: center;
      transform: rotate(0deg);
      transition-delay: 0s;
      transition-duration: 1.2s;
      z-index: 5;
    }

    .chart-path-partialbg3 {
      transform: rotate(-80deg);
    }

    .chart-path-partialbg2 {
      transform: rotate(-170deg);
    }

    .chart-path-partialbg1 {
      transform: rotate(-220deg);
    }

    .chart-path-bar {
      transform: rotate(0deg);
      transition-duration: 1.2s;
      z-index: 2;
    }
  }

  .chart-metric-scaleSegmented {
    .chart-path-great {
      transition-delay: 0.45s;
      transition-duration: 1.2s;
    }
    .chart-path-medium {
      transition-delay: 0.35s;
    }
    .chart-path-low {
      transition-delay: 0s;
    }
    .chart-path-partialbg1 {
      transform-origin: center;
      transform: rotate(0deg);
      transition-delay: 0s;
      z-index: 5;
    }
  }

  .chart-metric-ces {
    .chart-path-great {
      transition-delay: 0.35s;
      transition-duration: 1.2s;
    }
    .chart-path-medium {
      transition-delay: 0.25s;
    }
    .chart-path-low {
      transition-delay: 0s;
    }
    .chart-path-partialbg1 {
      transform-origin: center;
      transform: rotate(0deg);
      transition-delay: 0s;
      z-index: 5;
    }
  }

  .chart-path-great {
    transform: rotate(0deg);
    z-index: 2;
  }

  .chart-path-high {
    transform: rotate(0deg);
    transition-delay: 0.3s;
    transition-duration: 1.2s;
    z-index: 3;
  }

  .chart-path-medium {
    transform: rotate(0deg);
    z-index: 4;
  }

  .chart-path-low {
    transform: rotate(0deg);
    z-index: 5;
  }
}

.popover {
  font-family: 'Montserrat';
  max-width: fit-content;
  left: 5px !important;
}

.amp-switch-options {
  display: flex;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #e1e4e8;
  .amp-switch-option {
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-right: 1px solid #e1e4e8;
    &.text {
      min-width: 96px;
    }
    &.icon {
      min-width: 40px;
    }
    &:hover {
      background-color: #f6f6f6;
    }
    &:last-of-type {
      border: none;
    }
    span {
      font-size: 14px;
      color: rgba(166, 174, 188, 1);
    }
    i {
      font-size: 16px;
      color: rgba(166, 174, 188, 1);
    }
  }
  .amp-switch-option-active {
    background-color: #f6f6f6;
    span,
    i {
      color: rgba(54, 72, 102, 1);
    }
  }
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/*********************************
 ** Amplifique.me Design System **
 *********************************/

.amp-tag {
  border-radius: 4px;
  background-color: #dbe5ff;
  font-size: 12px;
  user-select: none;
  font-weight: 500;
  color: #007fff;
  padding: 2px 6px;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity ease-in-out 0.3s;
  i {
    font-size: 12px;
  }
  &:hover {
    opacity: 0.7;
    .bi-x-lg {
      margin-left: 4px;
      display: block;
    }
  }
  .bi-x-lg {
    display: none;
  }
}

.amp-search {
  display: flex;
  position: relative;
  width: 200px;
  input {
    background: transparent;
    padding-right: 32px;
  }
  i {
    color: #a6aebc;
    position: absolute;
    top: 6px;
    right: 12px;
    cursor: pointer;
  }
}

.amp-action {
  cursor: pointer;
  span,
  i {
    color: #364866;
    font-size: 14px;
    font-weight: 500;
  }
  i {
    margin-right: 8px;
  }
}

.amp-breadcrumbs {
  display: flex;
  align-items: center;
  span,
  i {
    margin-right: 8px;
    color: #445571;
    font-size: 16px;
    font-weight: 500;
  }
  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:last-child {
      font-weight: 700;
    }
  }
}

.amp-box-warning {
  background-color: #fff2bf;
  color: #b38f00;
  padding: 16px;
  border-radius: 6px;
}

.amp-horizontal-line {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.amp-tabs-margin-fix {
  margin-top: -24px !important;
}

// Quill

quill-editor {
  display: flex !important;
  flex-direction: column-reverse;
  background-color: white;
  .toolbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .wrapper {
      display: flex;
    }
  }
}

.attachments {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 8px;
  .file {
    background-color: #f1f2f4;
    padding: 4px 8px;
    color: #666666 !important;
    border: 1px solid #f0e5e6;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    i {
      top: 0 !important;
      right: 0 !important;
      position: relative !important;
      margin-right: 4px;
      font-size: 24px;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      .title {
        font-weight: 500 !important;
        font-size: 14px !important;
      }
      .size {
        font-size: 10px !important;
      }
    }
  }
}

.ql-container {
  border: none !important;
  min-height: 104px !important;
}

.ql-editor {
  min-height: 104px;
  font-family: 'Montserrat' !important;
  p {
    max-height: 350px;
    overflow-y: auto;
    padding-right: 8px;
    &:has(.mention) {
      overflow: hidden;
    }
  }
  img {
    width: 50%;
  }
}

.mention {
  border-radius: 20px;
  padding: 4px 8px;
  background: #e1e4e8;
  color: #8a94a6;
}

.ql-toolbar {
  border: none !important;
}

.ql-formats {
  width: 50%;
  margin-right: 0 !important;
  display: flex !important;
  .ql-picker-item {
    font-size: 16px !important;
    position: relative;
    input {
      opacity: 0;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      cursor: pointer;
      top: 0;
      left: 0;
      &::-webkit-file-upload-button {
        cursor: pointer;
      }
    }
  }
  .editor-icon {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    font-size: 20px;
    svg {
      display: none !important;
    }
  }
  .size-button-wrapper {
    position: relative;
    .ql-picker {
      width: 27px;
    }
    .ql-picker-label {
      opacity: 0;
    }
    .ql-picker-options {
      border: none;
      box-shadow: 0 8px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
      padding: 8px;
      .ql-picker-item {
        padding: 4px 8px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        cursor: pointer;
        height: 36px;
        border-radius: 4px;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          background-color: #f5f8ff;
        }
      }
    }
    .bi-fonts {
      position: absolute;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.ql-snow .ql-tooltip::before {
  content: 'Acessar:';
}

.ql-snow .ql-tooltip a.ql-action::after {
  content: 'Editar';
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: 'Salvar';
}

.ql-snow .ql-tooltip a.ql-remove::before {
  content: 'Remover';
}

.ql-snow .ql-tooltip[data-mode='link']::before {
  content: 'Link:';
}

.ql-mention-list-container-bottom {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 4px;
  background: white;
  max-height: 275px;
  font-family: 'MontSerrat';
  color: #364866;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding: 8px;
  .ql-mention-list {
    margin: 0;
    padding: 0;
    li {
      padding: 0.5rem 1rem;
      &::marker {
        font-size: 0;
      }
      &:hover {
        cursor: pointer;
        background: #f5f8ff;
        border-radius: 4px;
        color: #5885f7;
      }
    }
  }
}

.amp-ticket-content {
  ol,
  ul,
  p {
    margin: 0 !important;
    font-size: 14px !important;
  }
}
